import React from "react"
import WebsiteLayout from "../../components/layout/website/website-layout/website-layout"
import SEO from "../../services/seo"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"
import styles from "./index.module.scss"
import DirectLink from "../../components/common/direct-link/direct-link"
import auth from "../../services/auth"
import util from "../../services/util"

// const host = process.env.GATSBY_FEDIA_DMS_HOST || "localhost"
// const port = process.env.GATSBY_FEDIA_DMS_PORT || 1337
// const baseUrl = `https://${host}:${port}/`

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

const NewslettersPage = () => {
  const data = useStaticQuery(graphql`
    query PageNewslettersQuery {
      allStrapiNewsletters {
        nodes {
          newsletters {
            document {
              source_file
              title
            }
            issue
            title
            thumbnail
            toc
          }  
        }
      }
    }
  `)
  const newsletters = _.orderBy(
    data.allStrapiNewsletters.nodes[0].newsletters,
    ["issue"],
    ["desc"]
  )
  console.log("anna print newsletters:", data.allStrapiNewsletters.nodes[0])
  return (
    <WebsiteLayout>
      <SEO title="Newsletters" />
      <div className={styles.wrapper}>
        <h1>Newsletters</h1>
        <ul className={styles.newsletters}>
          {newsletters.map(m => (
            <li key={m.id}>
              <div className={styles.issueNumber}>
                No.<span>{m.issue}</span>
              </div>
              <div className={styles.thumbnail}>
                <img
                  src={`${util.baseUrl()}${m.thumbnail}`}
                  alt={m.title}
                />
              </div>
              <div className={styles.info}>
                <h3 className={styles.title}>{m.title}</h3>
                <p className={styles.subtitle}>{m.toc}</p>
                <DirectLink
                  linkTo={`${util.baseUrl()}download/${m.document.source_file}/${m.document.title}.pdf`}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </WebsiteLayout>
  )
}

export default NewslettersPage
